import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import logo from "./logo.png";

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SportsSoccerRoundedIcon from '@material-ui/icons/SportsSoccerRounded';
import MonetizationOnRoundedIcon from '@material-ui/icons/MonetizationOnRounded';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    maxWidth: 100,
  },
  userIdSpan: {
    position: 'absolute',
    right: '2vw',
  }
}));



export default function AppBarComp(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () =>{
    history.push('/');
  };

  const moveToReservation = () => {
    history.push('/reservation');
  };

  const moveToCommands = () => {
    history.push('/commands')
  }

  return (
    <div>
    <AppBar style ={{background: "#777777"}} position="static" color='secondary' open={true}>
      <Toolbar variant="dense" style={{color: "#ffffff"}} onClick={handleDrawerOpen}>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
        <MenuIcon />
        </IconButton>
        <img src={logo} alt="logo" className={classes.logo} />
        <Typography variant="h6" style={{fontSize:'1.5em', textTransform: 'uppercase', marginLeft:'1em'}}>
          Billetterie
        </Typography>
        <Typography variant="h6" className={classes.userIdSpan} style={{fontSize:'1em', textTransform: 'uppercase', marginLeft:'1em'}}>
          {props.userId}
        </Typography>
      </Toolbar>
    </AppBar>
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
    <div className={classes.drawerHeader}>
      <IconButton onClick={handleDrawerClose}>
        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </IconButton>
    </div>
      <Divider />
      
      <List>
          <ListItem button key='reservation' onClick={() => {moveToReservation()}}>
            <ListItemIcon><SportsSoccerRoundedIcon /></ListItemIcon>
            <ListItemText primary='Faire une résérvation' />
          </ListItem>
          <ListItem button key='commands' onClick={() => {moveToCommands()}}>
            <ListItemIcon><MonetizationOnRoundedIcon /></ListItemIcon>
            <ListItemText primary='Mes commandes' />
          </ListItem>
          <ListItem button key='logout' onClick={() => {logout()}}>
            <ListItemIcon><ExitToAppIcon /></ListItemIcon>
            <ListItemText primary='Se déconnecter' />
          </ListItem>
      </List>
    </Drawer>
  </div>
  )
}

