import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MatchsList from './MatchsList/Index';
import CategoriesList from './CategoriesList/Index';
import UserForm from './UserForm/Index';
import Recapitulatif from './Recapitulatif/Index';
import './Styles.css';
import Image from "../statics/bg.jpg";
import { sha512 } from 'js-sha512';
import pack from 'locutus/php/misc/pack';
import { makeBooking } from '../Utils/http';
import { Alert } from '@mui/material';
import { calculateTotalCommand } from '../utilities';
import { getBackendUrl } from '../configuration';


function areCategoriesSelected(commandData){
  return Object.values(commandData).reduce(
    (previousValue, currentValue) => previousValue + currentValue,
    0
  )
}

const steps = ['Choisissez votre match','Réservez vos tickets', 'Renseignez vos informations', 'Récapitulatif'];

function generateHash() {
  let hash = ''
  let payForm = document.pay_form;
  console.log(payForm);
  if (payForm) {
    for (var i = 0, element; element = payForm.elements[i++];) {
      if (element.name === "HASH")
          continue
      if (hash)
        hash = hash.concat('|', element.value);
      else
        hash = element.value
    }
    hash = hash.concat('|Zakitop123')
    hash = btoa(pack('H*', sha512(hash)));
  }
  return hash;
};

export default function Container() {
    const [cgvAcceptance, setCGVAcceptance] = useState(false);
    const [errorCGV, setErrorCGV] = useState(false);
    const [errorMaxQty, setErrorMaxQty] = useState(false);
    const [errorMinQty, setErrorMinQty] = useState(false);
    const [errorMissingFormData, setErrorMissingFormData] = useState(false);
    const [matchId, setMatchId] = useState(null);
    const [maxCommandQty] = useState(5);//useState([0,1,2,3,4,5]);
    const [categoriesData, setCategoriesData] = useState([]);
    const [userData, setUserData] = useState({
        nom: sessionStorage.getItem('nom'),
        prenom: sessionStorage.getItem('prenom'),
        cin: sessionStorage.getItem('cin'),
        email: sessionStorage.getItem('email'),
        tel: ''
    })

    const changeUserData = (target, value) =>{
        switch (target){
            case 'nom':
                setUserData({...userData, nom: value});
                break
            case 'prenom':
                setUserData({...userData, prenom: value});
                break
            case 'cin':
                setUserData({...userData, cin: value});
                break
            case 'email':
                setUserData({...userData, email: value});
                break
            case 'tel':
                setUserData({...userData, tel: value});
                break
            default:
                break
        }
    };
    const changeCommandQty = (qty, id) =>{
        let temp = commandData;
        temp[parseInt(id)] = parseInt(qty);
        setCommandData(temp);
    }
    const [commandData, setCommandData] = useState({});
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    if (step in Object.keys(completed))
    setActiveStep(step);
  };

  const handleComplete = (isPayement) => {
    if (isPayement){
      // send to back end to make reservation
      if (cgvAcceptance){
        let bookingData = [];
        for (const [key, value] of Object.entries(commandData)) {
          bookingData.push({'categoryId': key, 'qty': value});
        }
        makeBooking(bookingData);
      }
      else{
        setErrorCGV(true);
      }
    }
    else{
      if (activeStep === 1){
        if (areCategoriesSelected(commandData)===0){
          setErrorMinQty(true);
          return
        }
        if(areCategoriesSelected(commandData) > 5){
          setErrorMaxQty(true)
          return
        }
        setErrorMinQty(false);
        setErrorMaxQty(false);
      } 

      if (activeStep === 2 && (userData.nom === '' || userData.prenom === '' || userData.email === '' || userData.cin === '')){
        setErrorMissingFormData(true)
        return
      }
      else{
        setErrorMissingFormData(false)
      }
      const newCompleted = completed;
      newCompleted[activeStep] = true;
      setCompleted(newCompleted);
      handleNext();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div>
      <Box sx={{ width: '100%'}}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit" onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div>
          {allStepsCompleted() ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            
            <React.Fragment> 
              {
                activeStep !== 0 ? <img src={Image} width="80%" height="300px" alt="cam"/>: null
              }
              {          
                errorMaxQty ? <Alert severity="error">Nombre maximum de billet par commande dépassé !</Alert>: null
              }
              {
                errorMinQty ? <Alert severity="error">Vous devez selectionner au moins une catégorie !</Alert>: null
              }
              {
                errorCGV ? <Alert severity="error">Vous devez accepter les conditions de vente avant de procéder au paiement !</Alert>: null
              }
              {
                errorMissingFormData ? <Alert severity="error">Vous devez remplir tous les champs obligatoires !</Alert>: null
              }
              {  
                  activeStep === 0 ?
                  <MatchsList setCommandData={ setCommandData } setMatchId={ setMatchId } handleComplete={ handleComplete } matchId={ matchId }/> 
                  :
                  activeStep === 1 ?
                  <CategoriesList commandData={commandData} changeCommandQty={changeCommandQty} maxCommandQty={maxCommandQty} setCommandData={setCommandData} setCategoriesData={setCategoriesData} categoriesData={categoriesData} matchId={ matchId }/>
                  : 
                  activeStep === 2 ? 
                  <UserForm userData={userData} changeUserData={changeUserData}/> 
                  : 
                  <Recapitulatif cgvAcceptance={ cgvAcceptance } setCGVAcceptance={ setCGVAcceptance } commandData={commandData} userData={userData} categoriesData={categoriesData}/>

              }
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Précédent
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {
                  activeStep !== 0 ?
                  <Button onClick={() => handleComplete(activeStep === 3)} sx={{ mr: 1 }}>
                  {activeStep === 3
                          ? 'Payer'
                          : 'Suivant'}
                  </Button>
                  :
                  null
                }
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
      <form name="pay_form" method="post" action="https://testpayment.cmi.co.ma/fim/est3Dgate">
        <input type="hidden" name="amount" value={calculateTotalCommand(commandData, categoriesData)}/>
        <input type="hidden" name="BillToName" value={userData.prenom + " " + userData.nom}/>
        <input type="hidden" name="CallbackResponse" value="true"/>
        <input type="hidden" name="CallbackURL" value={getBackendUrl() + "/payment"}/>
        <input type="hidden" name="clientid" value="600001552"/> 
        <input type="hidden" name="currency" value="504"/>
        <input type="hidden" name="email" value={userData.email}/>
        <input type="hidden" name="failUrl" value={'http://' +window.location.host+"/commands"}/>
        <input type="hidden" name="hashAlgorithm" value="ver3"/>
        <input type="hidden" name="lang" value="fr"/>
        <input type="hidden" name="oid" value=""/>
        <input type="hidden" name="okUrl" value={'http://' +window.location.host+"/commands?error=commandError"}/>
        <input type="hidden" name="refreshtime" value="5"/>
        <input type="hidden" name="rnd" value=""/>
        <input type="hidden" name="storetype" value="3D_PAY_HOSTING"/>
        <input type="hidden" name="TranType" value="PreAuth"/>
        <input type="hidden" name="HASH" value={generateHash()}/>
		</form>
    </div>
  );
}
