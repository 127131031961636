import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import './Styles.css';


export default function CommandDetailComp(params) {
  const {open, setOpen, commandDetailData, downloadVoucher} = params;

  console.log(commandDetailData);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className='popupRecap'>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='dialogRecap'
      >
        <DialogTitle id="alert-dialog-title" className='title'>
          {"Détail de votre commande"}
        </DialogTitle>
        <DialogContent>
          <table className='tableRecap'>
            <tr>
              <th>Catégorie</th>
              <th>Quantité</th>
              <th>Prix unitaire</th>
              <th>Prix Total</th>
            </tr>
            {
              Object.keys(commandDetailData).map(function(key) {
                return(
                  <tr>
                    <td key={key}>{key}</td>
                    <td>{commandDetailData[key]['qty']}</td>
                    <td>{commandDetailData[key]['prix']}</td>
                    <td>{commandDetailData[key]['qty'] * commandDetailData[key]['prix']}</td>
                  </tr>
                )
              })
            }
          </table>
        </DialogContent>
        <DialogActions>
          <Button className='buttonDownload' onClick={downloadVoucher}>Télécharger mon voucher</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}