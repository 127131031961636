import axios from "axios";
import { sha512 } from 'js-sha512';
import pack from 'locutus/php/misc/pack';
import { getBackendUrl } from "../configuration";


function redirectToLoginPage(statusCode){
    console.log('Checking status', statusCode);
    if (statusCode === 401 || statusCode === 422){
         console.log('ici', window.location.host+'/');
         window.location.replace('http://'+window.location.host+'/');
    }
}

export const endpoints = {
    book: 'make_web_reservation',
    signup: 'sign-up',
    login: 'weblogin',
    categories: 'get_web_categories',
    matchs: 'get_web_matchs_list',
    commands: 'get_web_commands',
    commandDetail: 'get_command_detail',
    getvoucher: 'get_voucher',
    resetPassword: 'reset_password',
}

export default function getAxiosSession() {
    return axios.create({
        baseURL: getBackendUrl()+'/',
        headers: {"Authorization" : `Bearer ${sessionStorage.getItem('access_token')}`}
        }
    )
};

function generateHash() {
    let hash = ''
    let payForm = document.pay_form;
    console.log(payForm);
    if (payForm) {
      for (var i = 0, element; element = payForm.elements[i++];) {
        if (element.name === "HASH")
            continue
        if (hash)
          hash = hash.concat('|', element.value);
        else
          hash = element.value
      }
      hash = hash.concat('|Zakitop123')
      hash = btoa(pack('H*', sha512(hash)));
    }
    return hash;
  };

async function makeReservation(myAxios, endpoint, data){
    try {
        const response = await myAxios.post(endpoint, data);
        const {bookingId} = response.data;
        console.log('bookingId:',bookingId);
        let payForm = document.pay_form;
        if (payForm) {
            for (var i = 0, element; element = payForm.elements[i++];) {
              if (element.name === "oid" || element.name === "rnd"){
                  element.value = bookingId;
              }
              if (element.name === 'HASH'){
                  element.value = generateHash();
              }  
            }
            document.pay_form.submit();
        };
        return bookingId;
    }
    catch(e){
        redirectToLoginPage(e.response.status);
        alert("Reservation echouée, vérifier votre commande et contacter l'administrateur !");
    }
}

export const makeBooking = (commandData) =>{
    const endpoint = endpoints.book;
    const myAxios = getAxiosSession();
    console.log('BOOKING', commandData);
    let data = {
        matchId:1,
        commandList: commandData
    };
    makeReservation(myAxios, endpoint, data);
    
};

export const signUpHttp = async (identifiers) =>{
    const endpoint = endpoints.signup;
    const myAxios = getAxiosSession();
    
    try {
        const response = await myAxios.post(endpoint, identifiers);
        return response.data.error;
    }
    catch(e){
        return 'Une erreur technique a été rencontrée. Veuillez essayer ultérieurement.';
    }
}


export const loginHttp = async (identifiers) =>{
    const endpoint = endpoints.login;
    const myAxios = getAxiosSession();
    
    try {
        const response = await myAxios.post(endpoint, identifiers);
        if (response.status == 200){
            sessionStorage.setItem('access_token', response.data.access_token);
            sessionStorage.setItem('email', response.data.email);
            sessionStorage.setItem('nom', response.data.nom);
            sessionStorage.setItem('prenom', response.data.prenom);
            sessionStorage.setItem('cin', response.data.cin);
        }
        return response;
    }
    catch(e){
        return 'Une erreur technique a été rencontrée. Veuillez essayer ultérieurement.';
    }
}

export const getAvailableCategories = async (matchId, setCategoriesData, setRowData) =>{
    const endpoint = endpoints.categories + '?match_id=' + matchId;
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint);
        setCategoriesData(response.data);
        setRowData(response.data); 
        return response;
    }
    catch(e){
        redirectToLoginPage(e.response.status);
    }
}


export const getAvailableMatchs = async (setRowData) =>{
    const endpoint = endpoints.matchs
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint);
        setRowData(response.data); 
        return response;
    }
    catch(e){
        redirectToLoginPage(e.response.status);
    }
}

export const getCommands = async (setRowData) =>{
    const endpoint = endpoints.commands
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint);
        setRowData(response.data); 
        return response;
    }
    catch(e){
        redirectToLoginPage(e.response.status);
    }
}


export const getCommandDetail = async (commandId) =>{
    const endpoint = endpoints.commandDetail+'?cmd_id='+commandId
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint); 
        return response.data;
    }
    catch(e){
        return {}
    }
}


export const getVoucher = async (commandId) =>{
    const filename = 'Voucher'
    const endpoint = endpoints.getvoucher+'?cmd_id='+commandId
    const myAxios = getAxiosSession();
    try {
        const response = await myAxios.get(endpoint, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}-${+new Date()}.pdf`;
        link.click();
    }
    catch(e){
        return {}
    }
}

export const resetPasswordHttp = async (identifiers) =>{
    const endpoint = endpoints.resetPassword;
    const myAxios = getAxiosSession();
    
    try {
        await myAxios.post(endpoint, identifiers);
    }
    catch(e){
        alert('Une erreur technique a été rencontrée. Veuillez essayer ultérieurement.');
    }
}