import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';
import CGV from './Components/CGV/Index';
import LoginComp from './Components/LoginComp';
import LogOnComp from './Components/LogOnComp';
import CommandsComp from './Components/CommandsComp';
import ResetPasswordComp from './Components/ResetPasswordComp';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <div>
      <Route exact path="/" component={LoginComp} />
      <Route exact path="/sign-up" component={LogOnComp} />
      <Route exact path="/reservation" component={App} />
      <Route exact path="/cgv" component={CGV} />
      <Route exact path="/commands" component={CommandsComp} />
      <Route exact path="/reset-password" component={ResetPasswordComp} />
    </div>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
