import { react, useState } from 'react';
import './style.css';
import logo from "./logo.png";
import { resetPasswordHttp } from '../../Utils/http';
import { useHistory } from 'react-router-dom';
import { Alert } from '@mui/material';


export default function ResetPasswordComp(){
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [error, setError] = useState('');

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        if (password != passwordConfirmation)
            setError('Les deux mots de passes ne se correspondent pas !')
        
        else if (password.length < 8)
            setError('Taille du mot de passe insuffisante. Au moins 8 charactères')
        else{
            await resetPasswordHttp(
                {
                    'email': email,
                    'pwd': password,
                }
            )
            history.push('/?password_reset=true');
        }
    }

    return(
        <div class="auth">
            {
             error !=='' ?
             <Alert severity="error">{error}</Alert>
             :
             null   
            }
            <div class="auth-container">
                <div class="auth-logo">
                <img src={logo} />
                </div>
                <form onSubmit={handleSubmitLogin} class="auth-form">
                    <h1>Reinitialisation du mot de passe</h1>
                    <div class="auth-input">
                        <label for="email">
                        Email
                        </label>
                        <input required type="email" name="email" id="email" onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="password">
                        Mot de passe
                        </label>
                        <input required type="password" name="password" id="password" onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="passwordConfirmation">
                        Confirmer votre mot de passe
                        </label>
                        <input required type="password" name="passwordConfirmation" id="passwordConfirmation" onChange={e => setPasswordConfirmation(e.target.value)}/>
                    </div>
                    <button type="submit" name="submit" class="auth-btn">Valider</button>
                </form>
            </div>
        </div>
    );
}