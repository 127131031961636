import React, { useCallback, useRef, useState, Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './Styleag-grid.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getAvailableMatchs } from '../../Utils/http';

  
const MatchsList = (params) => {
    const {setMatchId, handleComplete, setCommandData} = params;
    const gridRef = useRef();
    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
      }, []);
    const [rowData, setRowData] = useState([]);

   const [columnDefs] = useState([
        { field: "id", hide: true },
        { field: "nom", resizable:true, headerName: "Match"},
        { field: "date", headerName: 'Date/Heure', valueGetter:function(params){return params.data.date+" "+params.data.heure} },
        { field: "competition", headerName: 'Compétition' },
        { field: "stade" },
        { 
            headerName: '',
            cellRenderer:function(params){
                return <button onClick={
                    ()=>{setCommandData({}); setMatchId(params.data.match_id); handleComplete();}
                } 
                type="button" className='choose'> Choisir </button>
            }
        }
   ]); 
   
   const onGridReady = async() => {
    getAvailableMatchs(setRowData);
   };

   

   return (
       <div className="ag-theme-alpine" style={{height: 450, width: '97%', marginTop: 10, marginLeft:'1.5%'}}>
           <AgGridReact
               rowData={rowData}
               columnDefs={columnDefs}
               onFirstDataRendered={sizeToFit}
               ref={gridRef}
               onGridReady={onGridReady}
               >
           </AgGridReact>
       </div>
   );
};

export default MatchsList;