import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

export default function UserForm(props) {
const {userData, changeUserData} = props;
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          required
          id="nom"
          label="Nom"
          placeholder="Nom"
          defaultValue={userData.nom}
          onChange={(e)=>changeUserData('nom', e.target.value)}
        />
    </div>
    <div>
        <TextField
          required
          id="prenom"
          label="Prénom"
          placeholder="Prénom"
          defaultValue={userData.prenom}
          onChange={(e)=>changeUserData('prenom', e.target.value)}
        />
        </div>
        <div>
        <TextField
          required
          id="cin"
          label="Numéro de CIN"
          placeholder="Numéro de la carte d'identité nationale"
          defaultValue={userData.cin}
          onChange={(e)=>changeUserData('cin', e.target.value)}
        />
      </div>
      <div>
      <TextField
          required
          id="email"
          label="Adresse Mail"
          placeholder="Adresse Mail"
          defaultValue={userData.email}
          onChange={(e)=>changeUserData('email', e.target.value)}
        />
    </div>
    <div>
        <TextField
          id="tel"
          label="Numéro de téléphone"
          placeholder="Numéro de téléphone"
          defaultValue={userData.tel}
          onChange={(e)=>changeUserData('tel', e.target.value)}
        />
      </div>
    </Box>
  );
}
