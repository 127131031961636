import { react, useState } from 'react';
import './style.css';
import logo from "./logo.png";
import { Alert } from '@mui/material';
import { loginHttp } from '../../Utils/http';
import { useHistory } from 'react-router-dom';


export default function LoginComp(){
    sessionStorage.clear();
    const history = useHistory();
    const displayNewUser = document.baseURI.toString().includes('?new_account=true');
    const displayResetPassword = document.baseURI.toString().includes('?password_reset=true');

    console.log(displayNewUser);

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        let resp = await loginHttp({'email': login, 'password': password});
        console.log(resp);
        if (resp.status === 200)
        history.push('/reservation');
    }

    const goToSignup = (e) =>{
        e.preventDefault();
        history.push('/sign-up')
    }

    const goToResetPassword = (e) =>{
        e.preventDefault();
        history.push('/reset-password')
    }

    return(
        <div class="auth">
            {
             displayResetPassword ?
             <Alert severity="success">Un mail vous a été envoyé pour valider votre demande de changement de mot de passe.</Alert>
             :
             null   
            }
            {
            displayNewUser ?
                <Alert severity="success">Félicitations, votre compte a été créé avec succès.</Alert>
                :
                null
            }
            <div class="auth-container">
                <div class="auth-logo">
                <img src={logo} />
                </div>
                <form onSubmit={handleSubmitLogin} class="auth-form">
                    <h1>Authentification</h1>
                    <div class="auth-input"> {/* ajouter la classe 'error' en cas d'erreur.*/}
                        <label for="login">
                        Email
                        </label>
                        <input required type="login" name="login" id="name" onChange={e => setLogin(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="password">
                        Mot de passe
                        </label>
                        <input required type="password" name="password" id="password" onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <button type="submit" name="submit" class="auth-btn">Se connecter</button>
                    <div class='login-footer'>
                        <a class='sign-up' href='#' onClick={goToSignup}>Créer un compte</a>
                        <a class='reset-password' href='#' onClick={goToResetPassword}>Mot de passe oublié</a>
                    </div>
                </form>
            </div>
        </div>
    );
}