import * as React from 'react';
import logo from './logo.svg';
import './App.css';
import Container from './Components/Container';
import { Paper } from '@mui/material';
import AppBarComp from './Components/AppBarComp';

function App() {
  return (
    <div>
      <AppBarComp userId={sessionStorage.getItem('email')}/>
    
    <div className="App">
      <header className="App-header">
        <Paper elevation={3} className='ContainerPapper'>
          <Container/>
        </Paper>
      </header>
    </div>
    </div>
  );
}

export default App;
