import { react, useState } from 'react';
import './style.css';
import logo from "./logo.png";
import { signUpHttp } from '../../Utils/http';
import { useHistory } from 'react-router-dom';
import { webSellerId } from '../../configuration';



export default function LogOnComp(){
    const history = useHistory();
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [cin, setCin] = useState('');
    const [email, setEmail] = useState('');
    const [gsm, setGsm] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [errorSignup, setErrorSignup] = useState('');

    const handleSubmitLogin = async (e) => {
        e.preventDefault();
        if (password != passwordConfirmation)
            setErrorSignup('Les deux mots de passes ne se correspondent pas !')
        
        else if (password.length < 8)
            setErrorSignup('Taille du mot de passe insuffisante. Au moins 8 charactères')
        else{
            let error = await signUpHttp(
                {
                    'nom': nom,
                    'prenom': prenom,
                    'cin': cin,
                    'email': email,
                    'password': password,
                    'gsm': gsm,
                    'websellerid': webSellerId,
                }
            )
            if (error == '')
                history.push('/?new_account=true');
            else
                setErrorSignup(error)
        }
    }

    return(
        <div class="auth">
            <div class="auth-container">
                <div class="auth-logo">
                <img src={logo} />
                </div>
                <form onSubmit={handleSubmitLogin} class="auth-form">
                    <h1>Inscription</h1>
                    <div class="auth-input">
                        <label for="nom">
                        Nom
                        </label>
                        <input required type="nom" name="nom" id="nom" onChange={e => setNom(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="prenom">
                        Prénom
                        </label>
                        <input required type="prenom" name="prenom" id="prenom" onChange={e => setPrenom(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="cin">
                        CIN
                        </label>
                        <input required type="cin" name="cin" id="cin" onChange={e => setCin(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="email">
                        Email
                        </label>
                        <input required type="email" name="email" id="email" onChange={e => setEmail(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="email">
                        Tel
                        </label>
                        <input required type="text" name="tel" id="tel" onChange={e => setGsm(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="password">
                        Mot de passe
                        </label>
                        <input required type="password" name="password" id="password" onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div class="auth-input">
                        <label for="passwordConfirmation">
                        Confirmer votre mot de passe
                        </label>
                        <input required type="password" name="passwordConfirmation" id="passwordConfirmation" onChange={e => setPasswordConfirmation(e.target.value)}/>
                    </div>
                    <button type="submit" name="submit" class="auth-btn">Valider</button>
                </form>
            </div>
        </div>
    );
}