import * as React from 'react';
import { calculateTotalCommand, getCategoryDetailsById } from '../../utilities';
import './Style.css';
import Checkbox from '@mui/material/Checkbox';


export default function Recapitulatif(props) {
const {userData, commandData, categoriesData, cgvAcceptance, setCGVAcceptance} = props;
function clicked(){
    console.log('click: ', !cgvAcceptance);
    setCGVAcceptance(cgvAcceptance => !cgvAcceptance)
}
function clickOnCGV(){
    window.open('http://' +window.location.host+'/cgv','_blank');
}
  return (  
      <div>
    <fieldset className='settings'>
    <legend>Informations client</legend>
    <fieldset className='info'>
    <table>
    <tr>
    <th>Nom</th>
    <td>{userData.nom}</td>
    </tr>
    <tr>
    <th>Prenom</th>
    <td>{userData.prenom}</td>
    </tr>
    <tr>
    <th>CIN</th>
    <td>{userData.cin}</td>
    </tr>
    </table>
    </fieldset>
    <fieldset className='contact'>
    <table>
    <tr>
    <th>Email</th>
    <td>{userData.email}</td>
    </tr>
    <tr>
    <th>Tel</th>
    <td>{userData.tel}</td>
    </tr>
    </table>
    </fieldset>
    </fieldset>
    <fieldset className='command'>
    <legend>Détails de la commande</legend>
    <table>
        {
            Object.keys(commandData).map((categoryId) => (
                commandData[categoryId] > 0 ?
                <tr key={categoryId}>
                    <th>
                    {getCategoryDetailsById(categoryId, categoriesData).Catégorie}
                    </th>
                    <td>{getCategoryDetailsById(categoryId, categoriesData).Prix} MAD</td>
                    <td>X</td>
                    <td>{commandData[categoryId]}</td>
                    <td>{getCategoryDetailsById(categoryId, categoriesData).Prix * commandData[categoryId]} MAD</td>
                </tr>
                :
                null
            ))
        }
        <tr key='TOTAL'>
                    <th><b>TOTAL</b></th>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{calculateTotalCommand(commandData, categoriesData)} MAD</td>
                </tr>
    </table>
    </fieldset>
    <Checkbox checked={cgvAcceptance} onClick={ clicked }/><a href='#' onClick={clickOnCGV}>J'ai lu et j'accepte les conditions générales de ventes</a>
    </div>
  );
}
