export const getCategoryDetailsById = (id, categoriesData) => {
    console.log('id', id, 'categories', categoriesData)
    let result = categoriesData.filter(category => {
        return category.id == id
    })
    return result[0];
}

export const calculateTotalCommand = (commandData, categoriesData) => {
    let total = 0;
    Object.keys(commandData).map((categoryId) => {
        let category = getCategoryDetailsById(categoryId, categoriesData);
        total += (commandData[categoryId] * category.Prix)
    });
    return total;
}
