import React, { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './Styleag-grid.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { getAvailableCategories } from '../../Utils/http';

const CategoriesList = (params) => {
    const gridRef = useRef();
    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
      }, []);
     
    const {matchId, changeCommandQty, maxCommandQty, setCommandData, setCategoriesData, commandData, categoriesData} = params;

    const [rowData, setRowData] = useState([]);

   const [columnDefs] = useState([
       { field: "id", hide: true },
       { field: "Catégorie", minWidth: 300, resizable:true},
       { field: "Prix", resizable:true },
       { field: "Quantité", resizable:true, hide: true, headerName: "Quantité disponible" },
       { field: "Choix Quantité", resizable:true, headerName: "Quantité", cellRendererFramework: (params) => 
       <div className="box" >
           <select defaultValue={commandData[params.data.id]} key={params.data.id} id={params.data.id} onChange={(e)=>changeCommandQty(e.target.value, params.data.id, categoriesData, e)}>
               {
                new Array(maxCommandQty+1).fill(0).map((k, v) => 
               <option id={v} key={params.data.id+'-'+v}>{v}</option>
               )
                }
           </select>
       </div>
       },
   ]); 
   
   const onGridReady = async() => {
    getAvailableCategories(matchId, setCategoriesData, setRowData);
   };

   return (
       <div className="ag-theme-alpine" style={{height: 450, width: '80%', marginTop: 10, marginLeft:'10%'}}>
           <AgGridReact
               rowData={rowData}
               columnDefs={columnDefs}
               onFirstDataRendered={sizeToFit}
               ref={gridRef}
               onGridReady={onGridReady}
               >
           </AgGridReact>
       </div>
   );
};

export default CategoriesList;