import React, { useCallback, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import './Styleag-grid.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AppBarComp from '../AppBarComp';
import { getCommandDetail, getCommands, getVoucher } from '../../Utils/http';
import CommandDetailComp from './CommandDetailComp';


export default function CommandsComp(){
    const [open, setOpen] = useState(false);
    const [commandDetailData, setCommandDetailData] = useState({});
    const [commandId, setCommandId] = useState(null);
    const gridRef = useRef();
    const sizeToFit = useCallback(() => {
        gridRef.current.api.sizeColumnsToFit();
      },
      []
    );

    const [rowData, setRowData] = useState([]);

    const [columnDefs] = useState([
        { field: "id", hide: true },
        { field: "match", minWidth: 200, resizable:true},
        { field: "date commande", minWidth: 150, resizable:true},
        { field: "Statut", resizable:true },
        { field: "Quantité", resizable:true, headerName: "Quantité" },
        { field: "Télécharger justificatif", resizable:false, headerName: "Télécharger justificatif" }
    ]); 
    
    const onGridReady = async() => {
        getCommands(setRowData);
    };

    const goToMatchBooking = async(row) => {
        let data = await getCommandDetail(row.data.id);
        if (data != {}){
            setCommandId(row.data.id);
            setCommandDetailData(data);
            setOpen(true)
        }
    }

    
    const downloadVoucher = async() => {
        await getVoucher(commandId);
    }
    
    return(
        <div>
            <AppBarComp userId={sessionStorage.getItem('email')}/>
            <div className="ag-theme-alpine" style={{height: 450, width: '80%', marginTop: 10, marginLeft:'10%'}}>
                <AgGridReact
                    rowData={rowData}
                    columnDefs={columnDefs}
                    onFirstDataRendered={sizeToFit}
                    ref={gridRef}
                    onGridReady={onGridReady}
                    onRowDoubleClicked={goToMatchBooking}
                    >
                </AgGridReact>
                <CommandDetailComp open={open} commandDetailData={commandDetailData} setOpen={setOpen} downloadVoucher={downloadVoucher}/>
            </div>
        </div>
    );
}